import { Suspense, useMemo } from "react";
import { PrebookingForm } from "./PrebookingForm";
import { CancelPrebooking, PreCancelQuestion } from "./CancelPrebooking";


// comment here
export function App() {

    const isCancel = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has('cancel')
    }, [])

    if (isCancel) {
        // return (<CancelPrebooking />)
        return (<PreCancelQuestion />)
    }

    return (
        <PrebookingForm />
    )
}