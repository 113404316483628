import { notifications } from '@mantine/notifications';
import { addDays, addMonths, eachDayOfInterval, format, isWeekend } from 'date-fns';
import { customAlphabet } from 'nanoid';
import { useEffect, type SetStateAction } from 'react';
import urlJoin from 'url-join';
import { useUpdateEffect } from 'usehooks-ts';
import * as Sentry from "@sentry/browser";
import { callSubmitForm } from '../helper/index'


// comment here
const customerNanoid = customAlphabet('123456789ABCDEFGHJKMNPQRSTUVWXYZ', 7);
const cancelNanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 12);

function calculateBusinessDays(startDate: string | number | Date, endDate: string | number | Date, holidays: Map<string, boolean>) {
    // Ensure startDate and endDate are Date objects
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    let businessDays = 0;
    let currentDate = startDate;

    while (currentDate <= endDate) {
        // Check if the current date is a weekend (Saturday or Sunday)
        if (!isWeekend(currentDate)) {
            // Check if the current date is a holiday
            if (!holidays.has(currentDate.toDateString())) {
                businessDays++;
            }
        }

        currentDate = addDays(currentDate, 1);
    }

    return businessDays;
}


export const findOnlyBusinessDaysOverMinimumDays = (referenceDate: Date, dateList: Date[], minimumDays: number, publicHolidays: Date[]) => {
    const holidaysMap = publicHolidays.reduce((map, holiday) => {
        map.set(holiday.toDateString(), true);
        return map;
    }, new Map());

    const sortedDateList = dateList.sort((a, b) => a.getTime() - b.getTime());

    let skip = false

    const asDateFiltered = sortedDateList?.filter((d) => {
        if (skip) { return true }
        const b = calculateBusinessDays(referenceDate, d, holidaysMap)
        const overMinDays = b > minimumDays;
        if (overMinDays) { skip = true }
        return overMinDays
    })

    return asDateFiltered
}

export function getDatesBetweenTodayAndThreeMonths() {
    const today = new Date();
    const threeMonthsFromNow = addMonths(today, 3);

    // Calculate the dates between today and three months from now
    const datesBetween = eachDayOfInterval({
        start: today,
        end: threeMonthsFromNow,
    });

    return datesBetween;
}

export function getWorkingDaysBetweenTodayAndThreeMonths(publicHolidays: Date[]) {
    const datesBetween = getDatesBetweenTodayAndThreeMonths();

    const holidaysMap = publicHolidays.reduce((map, holiday) => {
        map.set(holiday.toDateString(), true);
        return map;
    }, new Map());

    // Calculate the working days between today and three months from now
    const workingDaysBetween = datesBetween.filter((date) => {
        // Check if the current date is a weekend (Saturday or Sunday)
        if (isWeekend(date)) return false

        // Check if the current date is a holiday
        if (holidaysMap.has(date.toDateString())) return false
        return true
    });

    return workingDaysBetween;
}

export function getInitialValues() {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('test')) {
        return {
            email: 'jonathan@oralart.co.nz',
            dentistNameAndPractiseName: 'jonathan\'s Dental Practice',
            patientName: 'Patient Name',
            deliveryDate: '2023-09-08',
            adminDeliveryDate: undefined,
            dateSendImpressionToOralart: new Date(),
            typeOfImpression: ['Physical Impressions'],
            teethToBeRestored: '11, 12',
            restorationType: 'Crown',
            materialType: 'PFM',
            comments: 'comments',
            bookingMadeBy: 'Jonathan',
            implantWarning: [],
            dentureWhatItem: 'Bite Block',
            dentureUpperOrLower: ['Upper'],
            dentureTryInOrStraitToFinish: 'Try In',
            region: 'North Shore',
            commentWarning: [],
        };
    } else {
        return {
            email: '',
            dentistNameAndPractiseName: '',
            patientName: '',
            deliveryDate: '',
            adminDeliveryDate: undefined,
            dateSendImpressionToOralart: undefined,
            typeOfImpression: [],
            teethToBeRestored: '',
            restorationType: '',
            materialType: '',
            comments: '',
            bookingMadeBy: '',
            implantWarning: [],
            dentureWhatItem: '',
            dentureUpperOrLower: [],
            dentureTryInOrStraitToFinish: '',
            region: '',
            commentWarning: [],
        };
    }

}


export function formSubmit(
    form: any,
    setIsSubmitLoading: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; },
    setFormSubmitted: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; },
    isCrownAndBridge: boolean,
    acceptedDisclaimers: string[],
    instructionsUrl: string,
    showInstructions: boolean,
    itemValue: number | undefined,
    mainDate: Date | undefined
) {
    return form.onSubmit(
        async (v: any) => {
            try {
                setIsSubmitLoading(true);

                // const urlReal = new URL(url);
                // urlReal.searchParams.append('valueInputOption', 'user_entered');

                const body = {
                    formResponse: {
                        timestamp: format(new Date(), 'dd/MM/yyyy HH:mm:ss'),
                        emailAddress: v.email,
                        dentistPracticeName: v.dentistNameAndPractiseName,
                        patientName: v.patientName,
                        impressionType: v.typeOfImpression.join(', '),
                        returnDate: v.adminDeliveryDate !== undefined ? format(v.adminDeliveryDate, "EEE dd MMM yyyy") : format(new Date(v.deliveryDate), "EEE dd MMM yyyy"),
                        dateSend: v.dateSendImpressionToOralart !== undefined ? format(v.dateSendImpressionToOralart, "EEE dd MMM yyyy") : '',
                        comments: v.comments,
                        bookingMadeBy: v.bookingMadeBy,
                        referenceId: customerNanoid(),
                        cancelReferenceId: cancelNanoid(),
                        teethToBeRestored: v.teethToBeRestored,
                        restorationType: v.restorationType,
                        materialType: v.materialType,
                        item: v.dentureWhatItem,
                        dentureWhatItem: v.dentureWhatItem,
                        dentureUpperOrLower: v.dentureUpperOrLower.join(', '),
                        dentureTryInOrStraitToFinish: v.dentureTryInOrStraitToFinish,
                        type: "Prebook",
                        region: v.region,
                        isCrownAndBridge: isCrownAndBridge,
                        acceptedDisclaimers: acceptedDisclaimers.join(', '),
                        instructionsUrl: instructionsUrl,
                        showInstructions: showInstructions,
                        itemValue: itemValue,
                        mainDate: mainDate
                    }
                };

                // const sheetyResponseRequest = await fetch(urlReal, { method: 'POST', body: JSON.stringify(body), headers: { "Content-Type": "application/json", }, });
                // const sheetyResponseJson = await sheetyResponseRequest.json()
                // const sheetyId = sheetyResponseJson.formResponse.id

                // if (!sheetyResponseRequest.ok) {
                //     throw new Error('Sheety response not ok')
                // }

                const mailResponse = await callSubmitForm(body.formResponse)

                if (!mailResponse.ok) {
                    // const urlJoined = urlJoin(url, `${sheetyId}`);
                    // await fetch(urlJoined, { method: 'DELETE', })

                    throw new Error('Mail response not ok')
                }
                setFormSubmitted(true);

            }
            catch (error: any) {
                Sentry.captureException(error)
                if (error?.message === 'Mail response not ok') {
                    notifications.show({
                        title: 'Error',
                        message: 'There was an error submitting your form. Please double check your email address. You may have mistyped it',
                        autoClose: 15000,
                    })
                }
                else if (error?.message === 'Sheety response not ok') {
                    notifications.show({
                        title: 'Error',
                        message: 'There was an error submitting your form. There was an error adding your form to the database. Please try again a few times. If the problem persists, please contact support.',
                        autoClose: 15000,
                    })
                } else {
                    notifications.show({
                        title: 'Error',
                        message: 'There was an error submitting your form',
                        autoClose: 15000,
                    })
                }
            }
            finally {
                setIsSubmitLoading(false);
            }
        }, (errors: any) => {
            if (errors) {
                const keyWithLowestPosition = (() => {
                    const keys = Object.keys(errors);
                    const sortedKeys = keys.sort((a, b) => {
                        const elementA = document.getElementById(a);
                        const elementB = document.getElementById(b);
                        if (elementA == null || elementB == null) return 0;
                        return elementA.getBoundingClientRect().top - elementB.getBoundingClientRect().top;
                    });
                    return sortedKeys[0];
                })();
                const element = document.getElementById(keyWithLowestPosition);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });

                }
            }
        });
}


export function useRememberFieldsWithLocalStorage(
    form: any,
    isAdmin: boolean) {

    useUpdateEffect(() => {
        window.localStorage.setItem('oralart-prebooking-form', JSON.stringify(form.values));
    }, [form.values]);

    useEffect(() => {
        const storedValue = window.localStorage.getItem('oralart-prebooking-form');
        if (storedValue) {
            try {
                const x = JSON.parse(storedValue);
                form.setFieldValue('bookingMadeBy', x.bookingMadeBy);
                if (!isAdmin) {
                    form.setFieldValue('dentistNameAndPractiseName', x.dentistNameAndPractiseName);
                    form.setFieldValue('email', x.email);
                    form.setFieldValue('region', x.region);
                }
            } catch (e) {
                Sentry.captureException(e)
            }
        }
    }, []);
}

export function removeDuplicatesFromArray<T>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}

export function addWeekDays(date: Date, days: number, holidays: Date[] = []) {

    const holidaysMap = dateArrayToMap(holidays);

    let count = 0; //?
    let current = date;
    while (count < days) {
        current = addDays(current, 1); //?
        if (!isWeekend(current) && !holidaysMap.has(current.toDateString())) {
            count++;
        }
    }
    return current;
}

export function subtractWeekDays(date: Date, days: number, holidays: Date[] = []) {

    const holidaysMap = dateArrayToMap(holidays);

    let count = 0; //?
    let current = date;
    while (count < days) {
        current = addDays(current, -1); //?
        if (!isWeekend(current) && !holidaysMap.has(current.toDateString())) {
            count++;
        }
    }
    return current;

}

function getDayReason(date: Date, holidaysMap: Map<string, boolean>, defaultReason: string): string {
    if (isWeekend(date)) return 'weekend';
    if (holidaysMap.has(date.toDateString())) return 'holiday';
    return defaultReason;
}

export function subtractWeekDaysDebug(startingDate: Date, daysToSubtract: { amount: number, reason: string }[], holidays: Date[] = []) {
    const holidaysMap = dateArrayToMap(holidays);
    let currentDate = new Date(startingDate);
    let debugTimeline = [];
    let totalDaysSubtracted = 0;

    // Add starting date to debug info
    debugTimeline.push({
        date: format(currentDate, 'EEE dd MMM yyyy'),
        reason: getDayReason(currentDate, holidaysMap, ''),
        label: "return date"
    });

    for (const { amount, reason } of daysToSubtract) {
        let daysCount = 0;

        while (daysCount < amount) {
            currentDate = addDays(currentDate, -1);
            totalDaysSubtracted++;

            const dayReason = getDayReason(currentDate, holidaysMap, reason);

            const actualReason = dayReason === reason
            debugTimeline.unshift({
                date: format(currentDate, 'EEE dd MMM yyyy'),
                reason: dayReason,
                reasonBalance: actualReason ? `${daysCount + 1} out of ${amount}` : ''
            });

            if (actualReason) daysCount++;
        }
    }

    // Add label to the last item in debug info
    debugTimeline[0].label = "main date";

    return {
        result: currentDate,
        debug: {
            timeline: debugTimeline,
            args: {
                startingDate: format(startingDate, 'EEE dd MMM yyyy'),
                daysToSubtract: daysToSubtract
            }
        }
    };
}


function dateArrayToMap(dates: Date[]) {
    return dates.reduce((map, date) => {
        map.set(date.toDateString(), true);
        return map;
    }, new Map());
}

// export const crownBridgePrebookingUrl = 'https://api.sheety.co/6565224fa65a11082d88012dd5762961/[new]OralartPreBookingForm/formResponses';
// export const denturePrebookingUrl = 'https://api.sheety.co/6565224fa65a11082d88012dd5762961/[new]DenturePreBooking20 (responses)/formResponses';

