import { isNotEmpty, useForm } from '@mantine/form';
import "./index.css"
import { addMonths, eachDayOfInterval, format, isSameDay, isWeekend, max, parse } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import TeethSelection from './TeethSelection';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Text, Title, TextInput, Select, Stack, Button, Checkbox, Radio, SegmentedControl, SimpleGrid, Space, Textarea, CopyButton, Paper, Modal, Anchor, Code, Tooltip, useMantineTheme, ScrollArea, Alert, Group, Box, Grid } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { addWeekDays, formSubmit, getInitialValues, removeDuplicatesFromArray, subtractWeekDays, subtractWeekDaysDebug, useRememberFieldsWithLocalStorage } from './functions';
import type { CommentWarning, CommentWarnings, CrownBridgeDeliveryDates, CrownBridges, FirstAvailableMainDate, MinimumDays, PublicHolidays } from './types';
import { useAtom } from 'jotai';
import { productTypeAtom, isCrownBridgeAtom, isDentureAtom, isApplianceAtom, isDentureOrApplianceAtom } from './jotai';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import qs from "qs"
import * as chrono from "chrono-node";
import { ObjectToTableComponent } from '../objectExplorer/index.tsx';



function SubmitButton(props: { isSubmitLoading: boolean | undefined; }) {
  return (<Button loading={props.isSubmitLoading} sx={{
    backgroundColor: "#376E97"
  }} type="submit" mt={"md"}>Submit</Button>);
}


function FormSubmitted(props: { form: any }) {
  const [isCrownBridge] = useAtom(isCrownBridgeAtom)
  const [isDenture] = useAtom(isDentureAtom)
  const [isAppliance] = useAtom(isApplianceAtom)
  const form = props.form;
  const showInstructions = showInstructionsLink(form);
  const generatedUrl = generateInstructionsLink(isCrownBridge, form, isDenture, isAppliance);



  return (
    <>
      <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
        <Title size={"h2"}>Thank you for making a pre-booking</Title>
        <Text mt={"xs"}>You will receive a confirmation email shortly</Text>
        <Grid mt="xs">
          {showInstructions &&
            <Grid.Col span={6}>
              <Button sx={{
                backgroundColor: "#376E97"
              }} fullWidth component="a" href={generatedUrl} target="_blank" mt="xs">Send Instructions</Button>
            </Grid.Col>
          }
          <Grid.Col span={6}>
            <Button sx={{
              color: "#376E97",
              borderColor: "#376E97"
            }} fullWidth variant="outline" mt="xs" onClick={() => {
              window.location.reload();
            }}>New Pre-booking</Button>
          </Grid.Col>
        </Grid>

      </Paper >
    </>
  )
}

function showInstructionsLink(form: any) {
  const emails = [
    "admin@oralart.co.nz",
    "jonathan@oralart.co.nz",
    "julianne@oralart.co.nz",
    "auckland@dentalboutique.co.nz",
    "dr.natashamanuson@gmail.com",
    "dr.aiyaanabzal@gmail.com",
    "dr.reid.aesthetics@gmail.com",
    "graham@grahamshaw.co.nz",
    "draftab@dentalboutique.co.nz",
  ];

  const showInstructions = emails.includes(form.values.email);
  return showInstructions;
}

function generateInstructionsLink(isCrownBridge: boolean, form: any, isDenture: boolean, isAppliance: boolean) {
  const category = (() => {
    if (isCrownBridge) {
      if (form.values.restorationType === "Veneer") {
        return "Veneers";
      }
      else if (form.values.restorationType === "Implant Crown" || form.values.restorationType === "Implant Bridge") {
        return "Implant";
      }
      return "Crown & Bridge";
    } else if (isDenture) {
      return "Denture";
    } else if (isAppliance) {
      return "Appliance";
    }
  })();

  function generateUrlWithQs(baseUrl: any, hashParams = {}) {
    const hashString = qs.stringify(hashParams, { arrayFormat: 'repeat' });
    return `${baseUrl}#${hashString}`;
  }


  const baseUrl = 'https://oralart.retool.com/p/instructions';
  const hashParams = {
    dentist: form.values.dentistNameAndPractiseName,
    email: form.values.email,
    patientName: form.values.patientName,
    scanSendDate: form.values.dateSendImpressionToOralart ? format(form.values.dateSendImpressionToOralart, 'yyyy-MM-dd') : undefined,
    arch: form.values.dentureUpperOrLower,
    teeth: form.values.teethToBeRestored,
    category: category,
    type: form.values.restorationType,
    item: form.values.dentureWhatItem,
    appliance: form.values.dentureWhatItem,
    nextStep: form.values.dentureTryInOrStraitToFinish
  };
  const generatedUrl = generateUrlWithQs(baseUrl, hashParams);
  return generatedUrl;
}

export function PrebookingForm() {

  const { data: preBookingProductMenus } = useQuery({
    queryKey: ['preBookingProductMenus'],
    queryFn: async () => {
      const req = await axios.get('https://api.sheety.co/6565224fa65a11082d88012dd5762961/preBookingSetUp/crown%20&Bridge')
      const data: CrownBridges = req.data
      return data['crown &Bridge']
    },

  })


  const { data: publicHolidays } = useQuery({
    queryKey: ['holidays'],
    queryFn: async () => {
      const req = await axios.get('https://api.sheety.co/6565224fa65a11082d88012dd5762961/starparkSetup/publicHolidays')
      const data: PublicHolidays = req.data
      return data?.publicHolidays.map((x) => parse(x.date, 'dd/MM/yyyy', new Date()));
    }
  })


  const { data: firstAvailableMainDate } = useQuery({
    queryKey: ['firstAvailableMainDate'],
    queryFn: async () => {
      const req = await axios.get('https://api.sheety.co/6565224fa65a11082d88012dd5762961/preBookingSetUp/firstAvailableMainDate')
      const data: FirstAvailableMainDate = req.data
      return {
        crownBridge: parse(data?.firstAvailableMainDate[0]['crown &Bridge'], 'dd/MM/yyyy', new Date()),
        denture: parse(data?.firstAvailableMainDate[0].dentures, 'dd/MM/yyyy', new Date())
      }
    }
  })

  const { data: minimumDaysCalculation } = useQuery({
    queryKey: ['minimumDays'],
    queryFn: async () => {
      const req = await axios.get('https://api.sheety.co/6565224fa65a11082d88012dd5762961/preBookingSetUp/minimumDays')
      const data: MinimumDays = req.data
      return data.minimumDays
    }
  })

  const { data: bannedCommentsRaw } = useQuery({
    queryKey: ['commentWarnings'],
    queryFn: async () => {
      const req = await axios.get('https://api.retool.com/v1/workflows/d5473649-291b-41de-a8c4-1799ba261ac2/startTrigger?workflowApiKey=retool_wk_7c7db1b2c4f7462bb01065f5922c8fa7') //?
      const commentWarnings = req.data as CommentWarnings
      return commentWarnings
    }
  })
  const [excludedBannedCommentsIds, setExcludedBannedCommentsIds] = useState([] as number[]);

  const bannedComments = useMemo(() => {
    return bannedCommentsRaw?.filter((x) => !excludedBannedCommentsIds.includes(x.id))
  }, [bannedCommentsRaw, excludedBannedCommentsIds]);

  const excludedBannedComments = useMemo(() => {
    const x = bannedCommentsRaw?.filter((x) => excludedBannedCommentsIds.includes(x.id)) || []
    if (excludedBannedCommentsIds.includes(-1)) {
      x.push({
        override_text: "I am not requesting an earlier date"
      } as CommentWarning)
    }
    return x
  }, [bannedCommentsRaw, excludedBannedCommentsIds]);


  const regions = useMemo(() => {
    if (!minimumDaysCalculation) return []
    const regions = minimumDaysCalculation?.map((x) => x.region)
    const noDup = removeDuplicatesFromArray(regions)
    return noDup
  }, [minimumDaysCalculation])

  const impressionTypes = useMemo(() => {
    // prevents content shift when loading assuming there are 3 items
    // they have varying number of characters to prevent a bug where some of the radio buttons stay there after the real content loads
    if (!minimumDaysCalculation) return [' ', '  ', '   ']
    const impressionTypes = minimumDaysCalculation?.map((x) => x.impressionType)
    const noDup = removeDuplicatesFromArray(impressionTypes)
    return noDup
  }, [minimumDaysCalculation])


  const [productType, setProductType] = useAtom(productTypeAtom)
  const [isCrownBridge] = useAtom(isCrownBridgeAtom)
  const [isDenture] = useAtom(isDentureAtom)
  const [isAppliance] = useAtom(isApplianceAtom)
  const [isDentureOrAppliance] = useAtom(isDentureOrApplianceAtom)

  const form = useForm({
    initialValues: getInitialValues(),
    validate: {
      email: (value: string) => {
        if (value.length === 0) return 'Please enter your email address'
        if (!value.includes('@')) return 'Please enter a valid email address'
        if (value.includes(' ')) return 'Please enter a valid email address. Your email address cannot contain a space'
        return undefined
      },
      dentistNameAndPractiseName: isNotEmpty('Please enter your dentist name and practise name'), // shared
      patientName: isNotEmpty('Please enter your patient name'), // shared
      deliveryDate: (value: string) => { // shared
        if (chooseAnyDeliveryDate) return undefined;

        return value.length === 0 ? 'Please select a delivery date' : undefined;
      },
      adminDeliveryDate: (value: Date) => { // shared
        if (!chooseAnyDeliveryDate) return undefined;
        return value === undefined ? 'Please select a delivery date' : undefined;
      },
      dateSendImpressionToOralart: isNotEmpty('Please enter your date send impression to oralart'), // shared
      typeOfImpression: isNotEmpty('Please select your type of impression'), // shared
      bookingMadeBy: isNotEmpty('Please enter your booking made by'), // shared
      restorationType: isCrownBridge ? isNotEmpty('Please enter your restoration type') : undefined, // c&b
      materialType: isCrownBridge ? isNotEmpty('Please enter your material type') : undefined, // c&b
      implantWarning: (value: string[], values) => { // c&b
        if (!isCrownBridge) return undefined;
        if (['Implant Bridge', 'Implant Crown'].includes(values.restorationType)) {
          return (value.length === 0 ? 'Please tick the checkbox' : undefined);

        }
        return undefined
      },
      dentureWhatItem: isDentureOrAppliance ? isNotEmpty('Please select an item') : undefined, // denture
      dentureUpperOrLower: isDentureOrAppliance ? isNotEmpty('Please select upper or lower') : undefined, // denture
      dentureTryInOrStraitToFinish: (value) => { // denture
        if (!showDentureNextStep) return undefined
        if (value === '') { return 'Please select an option' }
        return undefined
      },
      region: isNotEmpty('Please select a practice Location'),
      teethToBeRestored: (value: string) => {
        if (!isCrownBridge) return undefined;
        if (value.match(/[^0-9, ]/)) return 'Only numbers, commas and spaces are allowed in this field'
        // if there isn't at least one number in the input return an error
        if (!value.match(/[0-9]/)) return 'At least one tooth is required'
        return undefined
      }
    }

  });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [chooseAnyDeliveryDate, setChooseAnyDeliveryDate] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [isCheckReturnDate, setIsCheckReturnDate] = useState(false)


  const isAdmin = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('admin')
  }, [])

  const dentureParam = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('denture')
  }, [])

  const debugParam = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has('debug')
  }, [])

  useRememberFieldsWithLocalStorage(form, isAdmin);

  const restorationTypeList = useMemo(() => {
    let x = preBookingProductMenus?.filter((x) => x.family === 'C&B')
    if (!isAdmin) {
      x = x?.filter((x) => x.adminOnly !== true)
    }
    const product = x?.map((x) => x.product) ?? []
    const noDuplicates = [...new Set(product)];
    return noDuplicates;
  }, [preBookingProductMenus]);

  const dentureProducts = useMemo(() => {
    if (!preBookingProductMenus) return []
    if (productType === "Crown & Bridge") return []

    let x = preBookingProductMenus?.filter((x) => { return x.family === 'Removables' && x.subCategory === productType })
    if (!isAdmin) {
      x = x?.filter((x) => x.adminOnly !== true)
    }
    // Remove duplicates with the same product name
    x = x.reduce((acc, current) => {
      const existingItem = acc.find(item => item.product === current.product);
      if (!existingItem) {
        acc.push(current);
      }
      return acc;
    }, [] as typeof x);

    const formatForSelect = x.map((y) => {
      if (isAdmin) {
        return ({ value: y.product, label: y.product, group: y.adminOnly ? 'Admin Only' : 'Public' })
      }
      return ({ value: y.product, label: y.product });
    })

    if (formatForSelect?.filter((x) => x.value === form.values.dentureWhatItem)?.length === 0) {
      form.setFieldValue('dentureWhatItem', '')
    }
    return formatForSelect;
  }, [preBookingProductMenus, productType]);

  const showDentureNextStep = useMemo(() => {
    const x = preBookingProductMenus?.filter((x) => { return x.family === 'Removables' && x.product === form.values.dentureWhatItem })
    // const value = x?.[0]?.nextStep ?? false
    const value = (x?.[0]?.nextStep2 ?? "") !== ""
    return value
  }, [form.values.dentureWhatItem, dentureProducts])


  const materialTypeList = useMemo(() => {
    if (!preBookingProductMenus) return []

    const restType = form.values.restorationType
    if (restType === '') return []

    const materials = preBookingProductMenus?.filter((x) => x.product === restType)?.map((x) => x.materials)

    if (!materials.includes(form.values.materialType)) {
      form.setFieldValue('materialType', '')
    }

    if (materials?.length === 1) {
      form.setFieldValue('materialType', materials[0])
    }

    return materials;

  }, [preBookingProductMenus, form.values.restorationType]);


  const daysToMake = useMemo(() => {
    if (!minimumDaysCalculation) {
      return undefined
    }

    const restMaterialCombo = preBookingProductMenus?.filter((x) => {
      if (isCrownBridge) {
        return x.product === form.values.restorationType && x.materials === form.values.materialType;
      } else {
        return x.product === form.values.dentureWhatItem && x.nextStep2 === form.values.dentureTryInOrStraitToFinish
      }
    })
    if (restMaterialCombo?.length !== 1) {
      return undefined
    }
    const typeOfImpression = form.values.typeOfImpression as string[]
    const [preMainDays, mainOnwards] = (() => {
      const physicalPreMain = restMaterialCombo?.[0].physPreMain
      const physicalMainOnwards = restMaterialCombo?.[0].physMainOnwards

      const digitalPreMain = restMaterialCombo?.[0].digPreMain
      const digitalMainOnwards = restMaterialCombo?.[0].digMainOnwards

      if (typeOfImpression.length === 1 && typeOfImpression[0] === 'Physical Impressions') {
        return [physicalPreMain, physicalMainOnwards]
      }
      if (typeOfImpression.length === 1 && typeOfImpression[0] === 'Digital Impressions (intraoral scan)') {
        return [digitalPreMain, digitalMainOnwards]
      }
      else {
        return [Math.max(physicalPreMain, digitalPreMain), Math.max(physicalMainOnwards, digitalMainOnwards)]
      }

    })();


    const locationRegionDays = minimumDaysCalculation?.filter((x) => typeOfImpression.includes(x.impressionType) && x.region === form.values.region)

    if (locationRegionDays?.length === 0) {
      return undefined
    }

    const locationRegionInboundDaysHighest = locationRegionDays?.reduce((prev, current) => (prev.inbound > current.inbound) ? prev : current).inbound
    const locationRegionOutboundDaysHighest = locationRegionDays?.reduce((prev, current) => (prev.outbound > current.outbound) ? prev : current).outbound
    if (locationRegionInboundDaysHighest !== undefined && locationRegionOutboundDaysHighest !== undefined) {
      // return [locationRegionInboundDaysHighest, locationRegionOutboundDaysHighest, preMainDays, mainOnwards]
      return {
        preMain: {
          inbound: locationRegionInboundDaysHighest,
          production: preMainDays
        },
        mainOnwards: {
          production: mainOnwards,
          outbound: locationRegionOutboundDaysHighest
        }
      }
    }

    return undefined

  }, [form.values.restorationType, form.values.materialType, preBookingProductMenus, form.values.typeOfImpression, form.values.region, minimumDaysCalculation, isCrownBridge]);

  const [deliveryDates, deliveryDatesDebug] = useMemo(() => {
    const debug = {} as Record<string, any>
    const debugTimeline = []
    if (form.values.dateSendImpressionToOralart === undefined || publicHolidays === undefined) return []
    debug['sendDate'] = form.values.dateSendImpressionToOralart
    const firstAvailableMainDate2 = isCrownBridge ? firstAvailableMainDate?.crownBridge : firstAvailableMainDate?.denture
    if (!firstAvailableMainDate2) return []
    debug['isCrownBridge'] = isCrownBridge
    debug['firstAvailableMainDate2'] = firstAvailableMainDate2
    if (!daysToMake) return []
    debug['daysToMake'] = daysToMake
    const sendDate = form.values.dateSendImpressionToOralart
    const sendDateMinusOne = subtractWeekDays(sendDate, 1) //?
    debugTimeline.push({
      date: sendDateMinusOne,
      reason: 'sendDate - 1'
    })
    const holidays = publicHolidays

    // } // get from sheets based on product type

    const preMainDate = addWeekDays(sendDateMinusOne, daysToMake.preMain.inbound + daysToMake.preMain.production, holidays)
    debugTimeline.push({
      date: preMainDate,
      reason: 'Pre Main Date'
    })

    const firstAvailableMainDateMinusOne = subtractWeekDays(firstAvailableMainDate2, 1, holidays)
    debugTimeline.push({
      date: firstAvailableMainDateMinusOne,
      reason: 'First Available Main Date - 1'
    })
    const mainDate = max([firstAvailableMainDateMinusOne, preMainDate])
    debugTimeline.push({
      date: mainDate,
      reason: 'Main Date'
    })
    const mainDatePlusProduction = addWeekDays(mainDate, daysToMake.mainOnwards.production, holidays)
    debugTimeline.push({
      date: mainDatePlusProduction,
      reason: 'Main Date + Production'
    })
    const mainDatePlusProductionPlusOutbound = addWeekDays(mainDatePlusProduction, daysToMake.mainOnwards.outbound, holidays)
    debugTimeline.push({
      date: mainDatePlusProductionPlusOutbound,
      reason: 'Main Date + Production + Outbound'
    })
    // const firstReturnDate = addWeekDays(mainDate, daysToMake.mainOnwards.production + daysToMake.mainOnwards.outbound, holidays) //?


    const subsequentDates = eachDayOfInterval({ start: mainDatePlusProductionPlusOutbound, end: addMonths(mainDatePlusProductionPlusOutbound, 12) }) //?
    const subsequentDatesFiltered = subsequentDates.filter(date =>
      !isWeekend(date) &&
      !(holidays.map(holiday => format(holiday, 'yyyy-MM-dd')).includes(format(date, 'yyyy-MM-dd')))
    );
    const formatForSelect = subsequentDatesFiltered?.map((d) => ({ value: format(d, "yyyy-MM-dd"), label: format(d, 'EEE dd MMM yyyy') }))

    if (form.values.deliveryDate) {
      if (formatForSelect?.find((x) => x.value === form.values.deliveryDate) === undefined) {
        form.setFieldValue('deliveryDate', '')
        form.setFieldError('deliveryDate', 'Please select a new date')
      }
    }

    // Process debugTimeline
    const minDate = debugTimeline.reduce((min, item) => item.date < min ? item.date : min, debugTimeline[0].date);
    const maxDate = debugTimeline.reduce((max, item) => item.date > max ? item.date : max, debugTimeline[0].date);

    // Fill in missing dates
    const fullTimeline = eachDayOfInterval({ start: minDate, end: maxDate }).map(date => ({
      date,
      reasons: [
        ...(isWeekend(date) ? ['weekend'] : []),
        ...(publicHolidays.some(holiday => isSameDay(holiday, date)) ? ['publicHoliday'] : [])
      ]
    }));

    // Group and combine reasons
    const groupedTimeline = debugTimeline.reduce((acc, item) => {
      const dateString = format(item.date, 'yyyy-MM-dd');
      if (!acc[dateString]) {
        acc[dateString] = { date: item.date, reasons: [] };
      }
      acc[dateString].reasons.push(item.reason);
      return acc;
    }, {} as Record<string, { date: Date; reasons: string[] }>);

    // Merge fullTimeline with groupedTimeline
    const processedTimeline = fullTimeline.map(item => {
      const dateString = format(item.date, 'yyyy-MM-dd');
      return groupedTimeline[dateString] || item;
    });

    return [
      formatForSelect,
      { processedTimeline, debug: debug }
    ] as const

  }, [form.values.dateSendImpressionToOralart, publicHolidays, daysToMake, chooseAnyDeliveryDate, isCrownBridge])


  const mainDate = useMemo(() => {
    if (!form.values.deliveryDate || !daysToMake || !publicHolidays) return undefined;
    return subtractWeekDaysDebug(
      new Date(form.values.deliveryDate),
      [{ amount: daysToMake.mainOnwards.outbound, reason: 'mainOnWards.outbound' }, { amount: daysToMake.mainOnwards.production, reason: 'mainOnWards.production' }],
      publicHolidays
    );
  }, [form.values.deliveryDate, daysToMake, publicHolidays]);

  const mainDatePlusOne = useMemo(() => {
    if (!mainDate || !publicHolidays) return undefined
    return addWeekDays(mainDate.result, 1, publicHolidays)
  }, [mainDate, publicHolidays])

  const itemValue = useMemo(() => {
    if (!preBookingProductMenus) return undefined;
    if (isCrownBridge) {
      if (!form.values.restorationType || !form.values.materialType || !form.values.teethToBeRestored) {
        return undefined;
      }
    }
    if (isDentureOrAppliance) {
      if (!form.values.dentureWhatItem || !form.values.dentureUpperOrLower) {
        return undefined;
      }
    }

    const teethVals = isCrownBridge ? form.values.teethToBeRestored : form.values.dentureUpperOrLower.join(", ")
    const teeth = teethVals.split(",").map(x => x.trim());
    const anterior = ["21", "22", "23", "11", "12", "13"];
    let unitTotal = 0;

    const product = isCrownBridge ? form.values.restorationType : form.values.dentureWhatItem
    const material = isCrownBridge ? form.values.materialType : ""
    const nextStep = (() => {
      if (isCrownBridge) { return "" }
      if (showDentureNextStep) { return form.values.dentureTryInOrStraitToFinish }
      return ""
    })()
    const productMenu = preBookingProductMenus.find(x =>
      x.product === product && x.materials === material && x.nextStep2 === nextStep
    );

    if (!productMenu) {
      return undefined;
    }

    const normalValue = productMenu.value;
    const anteriorValue = productMenu.anteriorOverride;

    for (let tooth of teeth) {
      if (anterior.includes(tooth)) {
        if (typeof anteriorValue === "number") {
          unitTotal += anteriorValue;
        }
        else {
          unitTotal += normalValue;
        }
      } else {
        unitTotal += normalValue;
      }
    }

    return unitTotal;
  }, [form.values.restorationType, form.values.materialType, form.values.teethToBeRestored, preBookingProductMenus, isCrownBridge, form.values.dentureUpperOrLower, form.values.dentureWhatItem, showDentureNextStep, form.values.dentureTryInOrStraitToFinish])


  // const url = (() => {
  //   if (isDentureOrAppliance) {
  //     return denturePrebookingUrl
  //   } else {
  //     return crownBridgePrebookingUrl
  //   }
  // })();

  const [questionModalOpened, { open: questionModalOpen, close: questionModalClose }] = useDisclosure(false);
  const [emailPhoneModalOpened, { open: emailPhoneModalOpen, close: emailPhoneModalClose }] = useDisclosure(false);

  const emailTemplate = [
    {
      value: form.values.email ?? "",
      title: "Email",
      hideIn: [""]
    },
    {
      value: form.values.dentistNameAndPractiseName ?? "",
      title: "Dentist Name & Practice Name",
      hideIn: [""]
    },
    {
      value: form.values.region ?? "",
      title: "Practice Location",
      hideIn: [""]
    },
    {
      value: form.values.patientName ?? "",
      title: "Patient Name",
      hideIn: [""]
    },
    {
      value: form.values.teethToBeRestored ?? "",
      title: "Teeth to be restored",
      hideIn: ["Appliance", "Denture"]
    },
    {
      value: form.values.restorationType ?? "",
      title: "Restoration Type",
      hideIn: ["Appliance", "Denture"]
    },
    {
      value: form.values.dentureWhatItem ?? "",
      title: "What Item",
      hideIn: ["Crown & Bridge",]
    },
    {
      value: form.values.dentureUpperOrLower ?? "",
      title: "Arch",
      hideIn: ["Crown & Bridge",]
    },
    {
      value: form.values.typeOfImpression ?? "",
      title: "Type of Impression",
      hideIn: [""]
    },
    {
      value: form.values.dateSendImpressionToOralart ? format(form.values.dateSendImpressionToOralart, 'EEE d MMMM yyyy') : '',
      title: "Date you will send the case to Oralart",
      hideIn: [""]
    },
    {
      value: form.values.deliveryDate ? format(parse(form.values.deliveryDate, 'yyyy-MM-dd', new Date()), 'EEE d MMMM yyyy') : '',
      title: "Delivery Date",
      hideIn: [""]
    },
    {
      value: form.values.comments ?? "",
      title: "Comments",
      hideIn: [""]
    },
    {
      value: form.values.bookingMadeBy ?? "",
      title: "Booking made by",
      hideIn: [""]
    },
  ]

  const clipboard = useClipboard({ timeout: 3000 });

  const crownEmailTemplate = emailTemplate.filter((x) => !x.hideIn.includes("Crown & Bridge")).map(x => `${x.title}: ${x.value}`).join("\n")
  const dentureEmailTemplate = emailTemplate.filter((x) => !x.hideIn.includes("Denture")).map(x => `${x.title}: ${x.value}`).join("\n")
  const applianceEmailTemplate = emailTemplate.filter((x) => !x.hideIn.includes("Appliance")).map(x => `${x.title}: ${x.value}`).join("\n")

  const theme = useMantineTheme();

  const matchingExcludedComment = (() => {
    if (!excludedBannedCommentsIds?.includes(-1)) {
      const results = chrono.parse(form.values.comments);

      if (results.length > 0) {
        return {
          id: -1,
          phrase: "",
          header: "Are you requesting an earlier return date ? ",
          paragraph: "The available dates displayed reflect our minimum turnaround times and our team's current capacity. Occasionally we are able to get cases back earlier, please call us on 09 478 3373 to discuss your options.",
          email_message: "Summary of case details",
          override_text: "I am not requesting an earlier date",
          email_phone: "Phone"
        } as CommentWarning
      }

    }

    const comments = (() => {
      if (!isCrownBridge) {
        return bannedComments?.filter(x => x.email_phone !== "Redirect")
      }
      return bannedComments
    })();

    return comments?.find((x) => form.values.comments.toLowerCase().includes(x.phrase.toLowerCase()))
  })();

  const emailTemplateFullText = (() => {
    return `${matchingExcludedComment?.email_message}\n\n${isCrownBridge ? crownEmailTemplate : ""}${isDenture ? dentureEmailTemplate : ""}${isAppliance ? applianceEmailTemplate : ""}`
  })();

  const acceptedDisclaimers = excludedBannedComments?.map((x) => x.override_text) ?? []

  const showInstructions = showInstructionsLink(form);
  const generatedInstructionsUrl = generateInstructionsLink(isCrownBridge, form, isDenture, isAppliance);

  const isCraigCampbellEmail = (() => {
    if (isAdmin) return false;

    const emails = [
      "receptionist@dentalontheshore.co.nz",
      "freda.zeng@lumino.co.nz",
      "craig@dentalontheshore.co.nz",
      "craig@bigredit.co.nz",
      "receptionist.dentalontheshore@lumino.co.nz",
      "odontologist@gmail.com",
      "deborah.yates@lumino.co.nz",
      "craig.campbell@lumino.co.nz"
    ];

    const emailIncludes = emails.some(email =>
      form.values.email?.toLowerCase().includes(email.toLowerCase())
    );

    const emailEndsWith = form.values.email?.toLowerCase().endsWith("@dentalontheshore.co.nz");

    const dentistNameAndPractiseName = form.values.dentistNameAndPractiseName?.toLowerCase().includes("craig campbell");

    return emailIncludes || emailEndsWith || dentistNameAndPractiseName;
  })();

  if (formSubmitted) {
    return (<FormSubmitted form={form} />)
  }


  return (
    <>
      <form onSubmit={formSubmit(form, setIsSubmitLoading, setFormSubmitted, isCrownBridge, acceptedDisclaimers, generatedInstructionsUrl, showInstructions, itemValue, mainDatePlusOne)}>
        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
          <Title size={"h2"}>Pre-Booking Form</Title>
          <Text mt={"xs"} >Call us on 09 478 3373 to pre-book other types of work<br />
            Please complete this form <u>as well as your usual lab prescription sheet</u>
          </Text>
          {isAdmin &&
            <SegmentedControl
              mt={"md"}
              fullWidth
              value={isCheckReturnDate ? 'Return Date' : 'Booking'}
              onChange={() => setIsCheckReturnDate(!isCheckReturnDate)}
              data={[
                { label: 'Booking', value: 'Booking' },
                { label: 'Return Date', value: 'Return Date' },
              ]}
            />
          }
        </Paper>

        {(dentureParam || isAdmin) &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <SegmentedControl
              fullWidth
              value={productType}
              onChange={setProductType}
              data={[
                { label: 'Crown & Bridge', value: 'Crown & Bridge' },
                { label: 'Denture', value: 'Dentures' },
                { label: 'Appliance', value: 'Appliances' },
              ]}
            />
          </Paper>
        }

        {!isCheckReturnDate &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <TextInput withAsterisk label="Email" placeholder="Your email address" {...form.getInputProps('email')} id='email'></TextInput>
          </Paper>
        }

        {!isCheckReturnDate &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <TextInput withAsterisk label="Dentist Name & Practice Name" placeholder="Your answer" {...form.getInputProps('dentistNameAndPractiseName')} id='dentistNameAndPractiseName' ></TextInput>
          </Paper>
        }

        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
          <Select withAsterisk clearable
            label={"Practice Location"}
            description="Select your region for an accurate delivery date to be calculated"
            placeholder="Your answer"
            {...form.getInputProps('region')}
            id='region'
            data={regions}
          ></Select>
        </Paper>

        {!isCheckReturnDate &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"} >
            <TextInput withAsterisk label="Patient Name" placeholder="Your answer" {...form.getInputProps('patientName')} id='patientName' ></TextInput>
          </Paper>
        }
        {
          (isDenture || isAppliance) &&
          <>
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
              <Select searchable withAsterisk clearable
                label={<><b>What item</b> (provide full details on lab form)</>}
                placeholder="Your answer"
                {...form.getInputProps('dentureWhatItem')}
                id='dentureWhatItem'
                data={dentureProducts}
              ></Select>
            </Paper>
            {!isCheckReturnDate &&
              <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Checkbox.Group withAsterisk label="Select Arch"
                  {...form.getInputProps('dentureUpperOrLower')}
                >
                  <Stack spacing="xs" my={"xs"} id='dentureUpperOrLower'>
                    <Checkbox value="Upper" label="Upper"></Checkbox>
                    <Checkbox value="Lower" label="Lower"></Checkbox>
                  </Stack>
                </Checkbox.Group>
              </Paper>
            }

            {showDentureNextStep &&
              <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Radio.Group withAsterisk label="Next Step" {...form.getInputProps('dentureTryInOrStraitToFinish')} >
                  <SimpleGrid cols={2} my={"xs"} id='dentureTryInOrStraitToFinish'>
                    {preBookingProductMenus
                      ?.filter(x => x.family === 'Removables' && x.product === form.values.dentureWhatItem)
                      .reduce((acc, curr) => {
                        if (!acc.includes(curr.nextStep2) && curr.nextStep2 !== "") {
                          acc.push(curr.nextStep2);
                        }
                        return acc;
                      }, [] as string[])
                      .map((step, index) => (
                        <Radio key={index} value={step} label={step} />
                      ))
                    }
                  </SimpleGrid>
                </Radio.Group>
              </Paper>
            }

          </>
        }

        {isCrownBridge &&
          <>
            {!isCheckReturnDate &&
              <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
                <Text size={"0.85rem"} fw={500} mb={"xs"}>Teeth to be restored</Text>
                <TeethSelection {...form.getInputProps('teethToBeRestored')} setValue={(teeth: string) => (form.setFieldValue("teethToBeRestored", teeth))} />
                <TextInput mt="xs" placeholder="Click to select teeth on the diagram" {...form.getInputProps('teethToBeRestored')} id='teethToBeRestored' ></TextInput>
              </Paper>
            }

            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
              <Select searchable withAsterisk id='restorationType'
                label={<><b>Restoration Type</b> (provide full details on lab form)</>}
                {...form.getInputProps('restorationType')}
                data={restorationTypeList?.map((restoration) => ({ value: restoration, label: restoration }))}
              />
              {['Implant Bridge', 'Implant Crown'].includes(form.values.restorationType) &&
                <Checkbox.Group
                  withAsterisk
                  {...form.getInputProps('implantWarning')}
                  label="This pre-booking is dependent on Oralart having the necessary implant parts in stock. If the parts are not in stock, the case may be delayed."
                  mt={"xs"}
                >
                  <Checkbox
                    value={"I understand"}
                    my="xs"
                    label="I understand"
                    id='implantWarning'
                  />
                </Checkbox.Group>

              }
            </Paper>

            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
              <Select
                withAsterisk
                searchable
                label={<><b>Material Type</b> (provide full details on lab form)</>}
                {...form.getInputProps('materialType')}
                id='materialType'
                data={materialTypeList.map((material) => ({ value: material, label: material }))}
              />
            </Paper>
          </>
        }


        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"} id='typeOfImpression'>
          <Checkbox.Group
            label="Type of Impression"
            description="Please select all that apply"
            {...form.getInputProps('typeOfImpression')}
          >
            <Stack spacing="xs" my={"xs"}>
              {impressionTypes.map(x => <Checkbox value={x} label={x} key={x}></Checkbox>)}
            </Stack>
          </Checkbox.Group>
        </Paper>

        {debugParam && (
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <div>mainDatePlusOne</div>
            <ObjectToTableComponent data={mainDatePlusOne} />
            <div>mainDate.debug</div>
            <ObjectToTableComponent data={mainDate?.debug} />
            <div>deliveryDatesDebug</div>
            <ObjectToTableComponent data={deliveryDatesDebug} />
          </Paper>
        )
        }


        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
          <DateInput
            minDate={isAdmin ? undefined : new Date()}
            withAsterisk label="Date you will send the case to Oralart" {...form.getInputProps('dateSendImpressionToOralart')} id='dateSendImpressionToOralart' />
        </Paper>


        <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
          {isAdmin &&
            <Checkbox
              my="xs"
              label="Admin Override - Choose any delivery date"
              checked={chooseAnyDeliveryDate}
              onChange={(e) => {
                const newState = e.currentTarget.checked
                setChooseAnyDeliveryDate(newState)
                if (newState === false) {
                  form.setFieldValue('adminDeliveryDate', undefined)
                }
              }}
            />
          }

          {!chooseAnyDeliveryDate &&
            <Select searchable withAsterisk label="Delivery Date"
              description="Book patient after NZ Courier's usual delivery time on this day"
              {...form.getInputProps('deliveryDate')}
              id='deliveryDate'
              data={deliveryDates || []}
              onFocus={async () => {
                const x = form.validate()
                const errors = x.errors
                if (errors) {
                  const keyWithLowestPosition = (() => {
                    const keys = Object.keys(errors);
                    const sortedKeys = keys.sort((a, b) => {
                      const elementA = document.getElementById(a);
                      const elementB = document.getElementById(b);
                      if (elementA == null || elementB == null) return 0;
                      return elementA.getBoundingClientRect().top - elementB.getBoundingClientRect().top;
                    });
                    return sortedKeys[0];
                  })();
                  await new Promise((resolve) => setTimeout(resolve, 50));
                  const element = document.getElementById(keyWithLowestPosition);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });

                  }
                }
              }}
            />
          }

          {chooseAnyDeliveryDate &&
            <DateInput
              minDate={form.values.dateSendImpressionToOralart}
              withAsterisk label="Delivery Date" {...form.getInputProps('adminDeliveryDate')} id='adminDeliveryDate' />
          }

        </Paper>

        {!isCheckReturnDate && !isCraigCampbellEmail &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <div>
              <TextInput onBlurCapture={() => {
                if (isAdmin) return
                if (matchingExcludedComment !== undefined) {
                  questionModalOpen()
                }
              }} label={<><b>Comments</b> (optional)</>} placeholder="Your answer" {...form.getInputProps('comments')} description="Comments are not read by a human until we receive your impression" ></TextInput>
            </div>
          </Paper>
        }
        {isCraigCampbellEmail &&
          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            {/* just say email us at admin@oralart.co.nz and have a disabled text input for comments */}
            <Text>Email us at admin@oralart.co.nz to leave comments</Text>
          </Paper>
        }
        {(excludedBannedComments?.length ?? 0) > 0 &&

          <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
            <Text size={"sm"} weight={700} >Accepted Disclaimers</Text>
            {excludedBannedComments?.map((x) => {
              return (
                <Checkbox mt={"xs"} color="dark" key={`disclaimer-checkbox${x}`} checked={true} label={x?.override_text} />
              )
            })
            }

          </Paper>
        }
        {!isCheckReturnDate &&
          <>
            <Paper shadow="xs" p="xl" mt={"md"} radius={"md"}>
              <TextInput withAsterisk label={<><b>Booking made by</b> (your name)</>} placeholder="Your answer" {...form.getInputProps('bookingMadeBy')} id='bookingMadeBy' ></TextInput>
            </Paper>
            <SubmitButton isSubmitLoading={isSubmitLoading}></SubmitButton>
            <div style={{ height: "30rem" }}>

            </div>
          </>
        }
      </form >
      <Modal centered size={"sm"} opened={questionModalOpened} withCloseButton={false} closeOnClickOutside={false} onClose={() => { }} >
        <Text fw={500} >{matchingExcludedComment?.header}</Text>
        <Group mt={"sm"} position='right'>
          <Button variant='outline' onClick={() => {
            if (matchingExcludedComment?.id) {
              setExcludedBannedCommentsIds(prev => [...prev, matchingExcludedComment.id])
              questionModalClose()
            }
          }}>No</Button>
          <Button onClick={() => {
            questionModalClose()
            emailPhoneModalOpen()
          }} >Yes</Button>
        </Group>
      </Modal>
      <Modal centered size={"lg"} opened={emailPhoneModalOpened} withCloseButton={false} closeOnClickOutside={true} onClose={() => {
        emailPhoneModalClose()
        questionModalOpen()
      }} >
        <Alert variant="light" color="green" title={(() => {
          if (matchingExcludedComment?.email_phone === "Email") return "Please email us"
          if (matchingExcludedComment?.email_phone === "Phone") return "Please call us"
          if (matchingExcludedComment?.email_phone === "Redirect") return "Please click the link below"
        })()} >
          <div dangerouslySetInnerHTML={{ __html: matchingExcludedComment?.paragraph ?? "" }}></div>
        </Alert>
        {
          matchingExcludedComment?.email_phone !== "Redirect" &&
          <>
            <Tooltip position={"top-end"} label={clipboard.copied ? 'Copied!' : 'Click to copy'} withArrow>
              <div onClick={() => clipboard.copy(emailTemplateFullText)}>
                <Code mt={"md"} block sx={{
                  cursor: 'pointer',
                  position: 'relative',
                  fontSize: "0.7rem",
                  border: `3px solid ${theme.colors.gray[4]}`,
                  '&:hover': {
                    borderColor: theme.colors.green[7]
                  },
                }}>
                  {emailTemplateFullText.trim()}
                </Code>
              </div>
            </Tooltip>
          </>
        }
        <Group mt={"md"} position="right" >
          <Button variant='outline' onClick={() => {
            emailPhoneModalClose()
            questionModalOpen()
          }}>Back</Button>
          {matchingExcludedComment?.email_phone === "Email" &&
            <Button onClick={() => {
              window.location.href = `mailto:admin@oralart.co.nz?subject=Prebooking request from online form&body=${encodeURIComponent(emailTemplateFullText)}`;
            }}>Email Us</Button>
          }
          {matchingExcludedComment?.email_phone === "Redirect" &&
            <Button onClick={() => {
              window.location.href = `https://prebook.oralart.co.nz/?denture`;
            }}>Denture Prebooking</Button>
          }
        </Group>
      </Modal>
      {/* <FormSubmitted form={form} /> */}

    </>
  );
}


